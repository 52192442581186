<template>
  <lab-task> <lab-mole-ratio /></lab-task>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabMoleRatio from '../simulations/LabMoleRatio';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'MoleRatio',
  components: {LabMoleRatio, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
